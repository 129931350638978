import { darken, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    '& > a': {
      color: theme.palette.common.white,
      fontSize: 'inherit',
      fontWeight: 'inherit',
      lineHeight: 'inherit',
      textDecoration: 'inherit',
      '&:hover': {
        opacity: 0.6,
        transition: '0.3s',
        cursor: 'pointer'
      }
    }
  },
  header: {
    '& > a': {
      color: theme.palette.common.white,
      '&:hover': {
        color: '#E9B109',
        opacity: 1
      }
    }
  },
  footer: {
    '& > a': {
      color: theme.palette.common.white,
      fontSize: 'inherit',

      '&:hover': {
        opacity: 1,
        color: darken(theme.palette.secondary.main, 0.1)
      }
    }
  },
  regular: {
    '& > a': {
      color: '#E9B109',
      textDecoration: 'underline'
    }
  },
  active: {
    color: '#E9B109 !important'
  }
}));
