import React from 'react';
import {alpha, darken, makeStyles} from '@material-ui/core/styles';
import {Container, Typography} from '@material-ui/core';
import dynamic from 'next/dynamic';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Link from '@material-ui/core/Link';
import clsx from 'clsx';

import {maybe} from 'src/core/utils';
import {extendedTypography} from 'src/themes/themeNext/extendedTypography';
import {showZopimDialog} from 'src/core/zopim';
import ReviewsWidgetItem from 'src/componentsNext/ReviewsWidgetItem';

import useStorefrontData from '../../hooks/useStorefrontData';
import StyledLabel from '../../components/StyledLabel';
import {Logo} from '../Header/components/Logo';

import Icon from 'src/components/CustomIcons/Icon';
import StyledLink from 'src/components/StyledLink';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: '#142241',
    borderTop: `1px solid ${theme.palette?.colors?.yellow[900] || 'yellow'}`
  },
  container: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3)
    }
  },
  content: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: theme.spacing(2),
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      gridAutoFlow: 'row',
      gridGap: theme.spacing(1.5)
    }
  },
  infoColumn: {
    display: 'grid',
    gridGap: theme.spacing(2.5),
    gap: theme.spacing(3),
    gridAutoRows: 'max-content',

    [theme.breakpoints.down('sm')]: {
      order: 3,
      justifyContent: 'center',
      textAlign: 'center',
      gap: theme.spacing(1)
    }
  },
  reviewsColumn: {
    display: 'grid',
    gridAutoRows: 'max-content',
    gap: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      gap: theme.spacing(4),
      flexWrap: 'wrap',
      position: 'relative',
      maxWidth: 322,
      margin: '0 auto'
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  footerNavColumn: {
    [theme.breakpoints.up('sm')]: {display: 'contents'},

    [theme.breakpoints.down('sm')]: {
      position: 'relative',

      display: 'grid',
      gridTemplateAreas: `'left right'
           'bottom bottom'`,
      justifyContent: 'center',
      gridColumnGap: theme.spacing(4),
      gridRowGap: theme.spacing(1.5),
      textAlign: 'center',

      '&:before': {
        content: '""',
        width: '100%',
        height: 1,
        position: 'absolute',
        top: 0,
        backgroundColor: alpha('#fff', 0.1)
      },

      '& > div:nth-child(1)': {
        gridArea: 'left',
        width: 140,
        paddingTop: theme.spacing(1.5)
      },
      '& > div:nth-child(2)': {
        gridArea: 'right',
        width: 140,
        paddingTop: theme.spacing(1.5)
      },
      '& > div:nth-child(3)': {
        gridArea: 'bottom',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: theme.spacing(1.5),
        position: 'relative',

        '& div ul': {
          display: 'flex !important',
          flexWrap: 'wrap',
          justifyContent: 'center',

          '& li': {
            width: 156,
            display: 'grid',
            justifyContent: 'center',
            gap: theme.spacing(1),
            gridAutoFlow: 'row',
            justifyItems: 'center',

            '& a': {
              ...extendedTypography.body2MediumMobile,

              [theme.breakpoints.down('sm')]: {
                color: theme.palette.common.secondary
              }
            }
          }
        },

        '&:before': {
          content: '""',
          width: '100%',
          height: 1,
          position: 'absolute',
          top: 0,
          backgroundColor: alpha('#fff', 0.1)
        },
        '&:after': {
          content: '""',
          width: '100%',
          height: 1,
          position: 'absolute',
          bottom: -13,
          backgroundColor: alpha('#fff', 0.1)
        }
      }
    },
    [theme.breakpoints.down('xs')]: {
      display: 'grid',
      gridTemplateAreas: `'left right'
           'bottom bottom'`,
      justifyContent: 'center',
      gridColumnGap: theme.spacing(3),
      gridRowGap: theme.spacing(1.5),

      '& > div:nth-child(1)': {
        gridArea: 'left',
        width: 120
      },
      '& > div:nth-child(2)': {
        gridArea: 'right',
        width: 120
      },
      '& > div:nth-child(3)': {
        gridArea: 'bottom',
        display: 'flex',
        justifyContent: 'center'
      }
    }
  },
  infoColumnIcons: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    gridGap: theme.spacing(3),
    margin: 0,

    '& a': {margin: 0}
  },
  infoColumnRow: {
    display: 'contents',

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      gap: theme.spacing(2),
      paddingTop: theme.spacing(1.5)
    }
  },
  title: {
    color: theme.palette.common.white,
    marginBottom: 16,
    ...extendedTypography.h5Desktop,

    [theme.breakpoints.down('sm')]: {
      ...extendedTypography.h4Mobile
    }
  },
  list: {
    display: 'grid',
    gap: theme.spacing(1),
    margin: 0,
    listStyle: 'none',
    paddingLeft: 0,

    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(1.25)
    }
  },
  listItem: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    alignItems: 'center',
    gridGap: theme.spacing(1.2),
    gridRowGap: theme.spacing(3),
    ...extendedTypography.body1RegularDesktop,

    // '& a': {
    //   color: theme.palette.common.white
    // },
    [theme.breakpoints.down('sm')]: {
      ...extendedTypography.body1RegularMobile
    }
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: 'rgba(255, 255, 255, 0.3)'
  },
  copyright: {
    color: theme.palette.common.white,
    fontSize: 13,
    lineHeight: '24px',
    whiteSpace: 'pre-line'
  },
  link: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    alignItems: 'center',
    gridGap: theme.spacing(1.2),
    gridRowGap: theme.spacing(3),

    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.common.white,
    ...extendedTypography.h6Desktop,

    '&:hover': {
      textDecoration: 'none',
      color: darken(theme.palette.secondary.main, 0.1),
      opacity: 1
    },

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      gridGap: theme.spacing(1),
      gridRowGap: theme.spacing(1),

      color: theme.palette.secondary.main,
      ...extendedTypography.body2MediumMobile
    }
  }
}));

const icons = {
  chat: {
    width: 36,
    height: 36,
    src: '/images/footer/chat.png'
  },
  support: {
    width: 36,
    height: 36,
    src: '/images/footer/email.png'
  }
};

const RenderIcon = ({name, isMobile}) => {
  const icon = icons[name];

  if (icon === undefined) {
    return null;
  }

  if (isMobile) {
    return <Icon {...icon} width={56} height={56} />;
  }

  return <Icon {...icon} />;
};

const SocialIcons = dynamic(() => import('src/componentsNext/SocialIconsNext'));

const FooterNext = () => {
  const classes = useStyles();
  const {shop} = useStorefrontData();
  const footerNavigation = maybe(
    () =>
      shop.menus.edges.find(({node: menu}) => {
        return menu.name === 'footer';
      })?.node.items,
    null
  );
  const isDownSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
  // const isDownXs = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const trustpilotReviews = {
    averageRating: 4.9,
    reviews: [],
    title: 'Excellent',
    totalReviews: 2000
  };

  const reviewsIo = {
    averageRating: 5,
    reviews: [],
    title: 'Excellent',
    totalReviews: '15,789'
  };

  return (
    <footer className={classes.root}>
      <Container className={classes.container}>
        <div className={classes.content}>
          <div className={classes.infoColumn}>
            <div className={classes.infoColumnRow}>
              {!isDownSm && <Logo width={170} height={64} />}
              <SocialIcons className={classes.infoColumnIcons} />
            </div>
            <Typography className={classes.copyright}>
              {shop && shop.footerText}
            </Typography>
          </div>
          <div className={classes.reviewsColumn}>
            <ReviewsWidgetItem
              provider='reviewsio'
              reviews={reviewsIo}
              size='medium'
            />
            {/*<ReviewsWidgetItem*/}
            {/*  provider='trustpilot'*/}
            {/*  reviews={trustpilotReviews}*/}
            {/*  size='medium'*/}
            {/*/>*/}
          </div>
          {footerNavigation && (
            <div className={classes.footerNavColumn}>
              {footerNavigation.map(item => {
                if (item.name.includes('hide')) {
                  return null;
                }

                return (
                  <div key={item.id}>
                    <div>
                      {item?.url && (
                        <Typography
                          className={classes.title}
                          variant='h6'
                          marked='left'
                        >
                          {item.name}
                        </Typography>
                      )}
                      <ul className={classes.list}>
                        {item.children.map(child => (
                          <li className={classes.listItem} key={child.id}>
                            <RenderIcon name={child.name} isMobile={isDownSm} />
                            <StyledLink
                              href={
                                child.page ? `/${child.page.slug}` : child.url
                              }
                              variant='footer'
                              title={child.name}
                            />
                            {child.label && (
                              <StyledLabel
                                title={child.label}
                                className={classes.label}
                              />
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                );
              })}
              <div>
                <div>
                  <Typography
                    className={classes.title}
                    variant='h6'
                    marked='left'
                  >
                    Need help?
                  </Typography>
                  <ul className={classes.list}>
                    <li className={classes.listItem}>
                      <Link
                        component='button'
                        onClick={() => showZopimDialog()}
                        classes={{root: clsx(classes.link)}}
                      >
                        <RenderIcon name='chat' isMobile={isDownSm} />
                        <span>24/7 Live Chat</span>
                      </Link>
                    </li>
                    <li className={classes.listItem}>
                      <Link
                        href='mailto:support@probemas.com'
                        classes={{root: clsx(classes.link)}}
                      >
                        <RenderIcon name='support' isMobile={isDownSm} />
                        <span>support@probemas.com</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
    </footer>
  );
};

export default FooterNext;
