import PropTypes from 'prop-types';
import Rating from '@material-ui/lab/Rating';
import {makeStyles} from '@material-ui/core/styles';

// import {ReviewsStarIcon} from './ReviewsStarIcon';
import {StarSimpleIcon} from 'src/componentsNext/icons/reviews/StarSimpleIcon';

const useStyles = makeStyles(theme => ({
  root: {gap: theme.spacing(0.5)},
  icon: {
    marginRight: theme.spacing(0.5),
    display: 'block',
    borderRadius: 2,
    overflow: 'hidden'
  },
  smallIcon: {
    width: 20,
    height: 20
  },
  mediumIcon: {
    width: 24,
    height: 24
  },
  largeIcon: {
    width: 28,
    height: 28
  }
}));

const CustomRating = ({rating}) => {
  const classes = useStyles();

  return (
    <Rating
      value={rating}
      precision={0.5}
      readOnly
      icon={<StarSimpleIcon width={16} height={16} />}
      emptyIcon={<StarSimpleIcon width={16} height={16} />}
      classes={{
        root: classes.root
      }}
    />
  );
};

CustomRating.propTypes = {
  rating: PropTypes.number,
  size: PropTypes.oneOf(['medium', 'small', 'large'])
};

export default CustomRating;
