import React from 'react';
import { Icon } from '@material-ui/core';
import Image from 'next/image';

const SocialIcon = ({ src, width, height }) => (
  <Icon
    style={{
      display: 'inline-flex',
      alignItems: 'center',
      width: width,
      height: height
    }}
  >
    <Image src={src} alt="star" width={width} height={height} />
  </Icon>
);

export default SocialIcon;
