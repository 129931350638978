export const StarSimpleIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={32}
    height={32}
    viewBox='0 0 32 32'
    fill='none'
    {...props}
  >
    <path
      fill='currentColor'
      d='M23.244 18.592c-.582.466-.816 1.165-.582 1.864.582 1.863 1.863 5.707 2.562 8.27 0 .35 0 .699-.35.932-.349.233-.698.233-1.048 0-2.096-1.631-5.125-3.844-6.64-5.126-.582-.465-1.514-.465-2.096 0-1.514 1.165-4.543 3.495-6.64 5.126-.233.233-.699.233-1.048 0-.35-.233-.466-.583-.35-.932.816-2.563 1.98-6.407 2.563-8.27.233-.7 0-1.515-.582-1.864-1.515-1.165-4.66-3.495-6.64-5.01-.35-.232-.466-.582-.35-.93 0-.35.467-.583.816-.583h8.038c.698 0 1.397-.466 1.63-1.165.583-1.864 1.864-5.708 2.68-8.154a.876.876 0 0 1 .815-.583c.35 0 .699.233.815.583.816 2.563 2.097 6.29 2.68 8.154.232.699.931 1.165 1.63 1.165h8.038c.35 0 .699.233.815.582 0 .35 0 .699-.233.932-2.097 1.514-5.125 3.844-6.64 5.009h.117Z'
    />
  </svg>
);
