import React, {useContext, useMemo} from 'react';
import Image from 'next/image';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import CustomRating from './CustomRating';

const useStyles = makeStyles(theme => ({
  root: {
    color: '#ffffff',
    display: 'grid',
    gridGap: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      gridGap: theme.spacing(0.5),
      // maxWidth: 150,
      padding: theme.spacing(0.5, 0)
    }
  },
  rootSmall: {gridGap: theme.spacing(0.5)},
  imageBox: {
    display: 'flex',
    alignItems: 'center',
    height: 15
  },
  smallDesc: {
    fontSize: 10,
    lineHeight: '13px',
    fontWeight: 500
  },
  mediumDesc: {
    fontSize: 12,
    lineHeight: '15px',
    fontWeight: 500
  },
  largeDesc: {
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500
  },
  text: {
    color: '#ffffff',

    fontSize: 11,
    lineHeight: 1.2,
    fontWeight: 500
  },
  trustpilot: {
    color: '#00B67A'
  },
  reviewsio: {
    color: '#E9B109'
  }
}));

const providerDetails = {
  trustpilot: {
    link: 'https://www.trustpilot.com/review/probemas.com',
    image: {width: '104', height: '24', src: '/images/trustpilot-logo.png'}
  },
  reviewsio: {
    link: 'https://www.reviews.io/company-reviews/store/probemas-com',
    image: {width: '118', height: '20', src: '/images/reviewsio-logo.png'}
  }
};

const Index = ({reviews, provider}) => {
  const classes = useStyles();

  if (!reviews) {
    return null;
  }

  return (
    <a
      href={providerDetails[provider].link}
      target='_blank'
      rel='noopener noreferrer'
    >
      <div className={clsx(classes.root)}>
        <div className={classes.imageBox}>
          <Image
            src={providerDetails[provider].image.src}
            width={providerDetails[provider].image.width}
            height={providerDetails[provider].image.height}
            priority
            alt={provider}
          />
        </div>

        <Typography className={clsx(classes.text)}>
          {reviews.title}{' '}
          <span className={classes[provider]}>
            {/*{reviews.averageRating}*/}4.91{' '}
          </span>
          out of <span className={classes[provider]}>5</span>
        </Typography>

        <CustomRating
          rating={reviews.averageRating}
          singleColor='reviewsio'
          provider={provider}
        />
        <Typography className={clsx(classes.text)}>
          Based on
          {'  '}
          <span className={clsx(classes.text, classes[provider])}>
            {reviews.totalReviews}
            {/*{'+ '}*/} Reviews
          </span>
        </Typography>
      </div>
    </a>
  );
};

Index.propTypes = {
  provider: PropTypes.oneOf(['trustpilot', 'reviewsio']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  reviews: PropTypes.object
};

export default Index;
