import * as React from 'react';
import clsx from 'clsx';
import {useRouter} from 'next/router';
import PropTypes from 'prop-types';
import Link from 'next/link';

import {useStyles} from './styled';

const bypass = 'https://probemas.com/boosting';

const StyledLink = ({href, route, params, variant, className, title}) => {
  const classes = useStyles();
  const router = useRouter();
  const isActive = router.asPath === href;
  const link = href || route;
  const linkType = variant || 'regular';
  let newWindow = false;
  const linkProps = {};

  if (href.includes('http')) {
    newWindow = !href.includes(process.env.ORIGIN) || href === bypass;

    if (newWindow) {
      linkProps.rel = 'noopener';
      linkProps.target = '_blank';
    }
  }

  return (
    <span className={clsx(classes.root, classes[linkType], className)}>
      <Link
        href={link || '#'}
        passHref
        className={clsx(isActive && classes.active)}
      >
        {title}
      </Link>
    </span>
  );
};

StyledLink.propTypes = {
  href: PropTypes.string,
  route: PropTypes.string,
  params: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string
};

export default StyledLink;
